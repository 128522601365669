.certificate-confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000009c;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    

    .modal-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 620px;
        margin: 10px;
        border-radius: 40px;
        background-color: $color-white;
        padding: 72px 24px 40px;
        position: relative;

        @include respond-points(0, 767){
            padding: 72px 15px 32px;
        }

        .modal-close {
            position: absolute;
            top: 24px;
            right: 24px;
            width: 24px;
            height: 24px;
            background-image: url('../../../../images/close.svg');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            cursor: pointer;
            transform-origin: center;
            transform: rotate(0);
            transition: transform .3s;

            &:hover {
                transform: rotate(180deg);
            }
        }

        .modal-text {
            display: flex;
            flex-direction: column;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: $color-black;
            font-family: $font-sec;

            @include respond-points(0, 767){
                font-size: 16px;
                line-height: 24px;
            }
            
            span {
                margin-top: 40px;
                font-weight: 500;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                text-transform: uppercase;
                color: $color-black;
                font-family: $font;
                word-break: break-word;

                @include respond-points(0, 767){
                    font-size: 24px;
                    line-height: 32px;
                    margin-top: 32px;
                }
            }
        }

        .confirm-modal-actions {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .modal-action {
                width: 100%;

                &:first-of-type {
                    margin-bottom: 24px;
                }

                &.btn-cancel {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    text-transform: uppercase;
                    color: $color-orange;
                    cursor: pointer;
                    transition: color $att;

                    @include respond-points(768, 1919){
                        font-size: 24px;
                        line-height: 32px;
                    }
                
                    @include respond-points(0, 767){
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &:hover {
                        color: $color-purple;
                    }
                }

                &.btn-confirm {
                    font-size: 24px;
                    line-height: 32px;

                    @include respond-points(768, 1919){
                        font-size: 24px;
                        line-height: 32px;
                    }
                
                    @include respond-points(0, 767){
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}