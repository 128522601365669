.course-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    @include respond-points(768, 1279){
        margin-top: 54px;
    }

    @include respond-points(0, 767){
        margin-top: 30px;
    }

    .no-data {
        font-family: $font-sec;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: $color-black;
        transition: color $att;

        .App.dark & {
            color: $color-white;
        }
    }
}