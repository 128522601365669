.ModuleItem {
    padding: 48px 40px;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid $color-black;
    background-color: $color-white;

    &:last-of-type {
        border: none;
    }

    @include respond-points(1280, 1500) {
        padding: 40px 30px;
    }

    @include respond-points(768, 1279) {
        padding: 32px 24px;
    }

    @include respond-points(0, 767) {
        padding: 32px 15px;
    }

    .module-position {
        font-weight: 800;
        font-size: 80px;
        line-height: 88px;
        text-transform: uppercase;
        color: $color-black;

        @include respond-points(768, 1500) {
            font-size: 48px;
            line-height: 56px;
        }

        @include respond-points(0, 767) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .module-name {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: $color-black;
        margin-top: 16px;
        word-wrap: break-word;

        @include respond-points(768, 1500) {
            font-size: 24px;
            line-height: 32px;
        }

        @include respond-points(0, 767) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .module-description {
        font-family: $font-sec;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
        margin-top: 16px;
    }

    .module-lessons {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        @include respond-points(0, 1500) {
            margin-top: 32px;
        }
    }

    .module-closed {
        padding: 24px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;
        color: $color-black;
        background-color: $color-orange;
        margin-top: 30px;

        @include respond-points(768, 1500) {
            padding: 20px;
            font-size: 16px;
            line-height: 24px;
        }

        @include respond-points(0, 767) {
            padding: 15px;
            font-size: 14px;
            line-height: 20px;
            margin-top: 20px;
        }
    }

}