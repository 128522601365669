.PersonalCabinet {

    display: flex;
    flex-direction: column;
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;    

        .cabinet-content {
            display: flex;
            margin-top: 60px;

            @include respond-points(768, 1279){
                margin-top: 48px;
            }

            @include respond-points(0, 1000){
                flex-direction: column;
            }

            @include respond-points(0, 767){
                margin-top: 30px;
            }

            .cabinet-main {
                display: flex;
                flex-direction: column;
                width: 60%;

                @include respond-points(0, 1000){
                    width: 100%;
                }
            }

            .cabinet-side {
                display: flex;
                flex-direction: column;
                width: 40%;
                padding-left: 30px;

                @include respond-points(0, 1000){
                    width: 100%;
                    padding-left: 0;
                    margin-top: 24px;
                }
            }
        }

    }
}