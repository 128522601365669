.CourseItem {
    display: flex;
    width: calc( (100% / 3) - ((30px * 2) / 3) );
    margin: 0 30px 30px 0;
    transition: filter $att;
    position: relative;


    &:nth-of-type(6n + 1){
        background-color: $color-white;
        border-radius: 40px;
    }

    &:nth-of-type(6n + 2){
        background-color: $color-pink;
        transition: background-color $att;

        .App.dark & {
            background-color: $color-blue;
        }
    }

    &:nth-of-type(6n + 3){
        background-color: $color-white;
        border-radius: 40px;
    }

    &:nth-of-type(6n + 4){
        background-color: $color-purple;
        border-radius: 0;
    }

    &:nth-of-type(6n + 5){
        background-color: $color-white;
        border-radius: 40px;
    }

    &:nth-of-type(6n + 6){
        background-color: $color-orange-light;
        border-radius: 0;
    }

    @include respond-points(768, 1000){
        width: 100%;
        margin: 0 0 24px 0;
    }

    @include respond-points(0, 767){
        width: 100%;
        margin: 0 0 15px 0;
    }

    @include respond(1001){
        &:nth-of-type(3n){
            margin-right: 0;
        }
    }

    &:hover {
        .course-title {
            &::after {
                left: 0;
                opacity: 1;
            }
        }
    }

    a {
        text-decoration: none;
        width: 100%;
    }

    .course-item-content {
        display: flex;
        flex-direction: column;
        padding: 60px 30px;

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }
    
        @include respond-points(0, 767){
            padding: 40px 15px;
        }
    }

    .course-title {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: $color-black;
        margin-bottom: 16px;
        word-wrap: break-word;

        &::after {
            content: '';
            min-width: 24px;
            width: 24px;
            height: 24px;
            margin-left: 15px;
            background-image: url('../../../../images/arrow.svg');
            background-size: 24px 24px;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            position: relative;
            left: 20px;
            opacity: 0;
            filter: invert(1);
            transform: scaleX(-1);
            transition: left $att, opacity $att;

            @include respond-points(768, 1400){
                min-width: 20px;
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
                margin-left: 12px;
            }

            @include respond-points(0, 767){
                min-width: 14px;
                width: 14px;
                height: 14px;
                background-size: 14px 14px;
                margin-left: 10px;
            }
        }

        @include respond-points(768, 1400){
            font-size: 24px;
            line-height: 32px;
        }
    
        @include respond-points(0, 767){
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 8px;
        }
    }

    .course-description {
        font-family: $font-sec;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .course-type {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80px;
        height: 80px;
        border-radius: 50%;

        @include respond-points(1280, 1919){
            width: 64px;
            height: 64px;
        }

        @include respond-points(0, 1279){
            width: 56px;
            height: 56px;
        }
    
        &.free {
            background-color: $color-green;
            color: $color-white;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            @include respond-points(1280, 1919){
                font-size: 14px;
                line-height: 20px;
            }
    
            @include respond-points(0, 1279){
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}