.cutdown-timer {
    display: flex;
    user-select: none;
    pointer-events: none;
    margin-top: 20px;

    .cutdowm-cell-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 5px;

        .cutdown-cell-value {
            width: 60px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
        }

        .cutdown-cell-label {
            font-size: 16px;
            font-weight: 500;
            color: $color-black;
            margin-top: 3px;
        }
    }
}