.BackButton {
    display: flex;

    a {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        background-color: $color-black;
        border-radius: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: $color-white;
        cursor: pointer;
        text-decoration: none;
        transition: background-color $att;

        @include respond-points(768, 1279){
            font-size: 14px;
            line-height: 20px;
        }

        @include respond-points(0, 767){
            padding: 8px;
            
            span {
                display: none;
            }
        }

        &:before {
            content: '';
            width: 16px;
            height: 16px;
            background-image: url('../../../images/arrow.svg');
            background-size: 16px 16px;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 16px;

            @include respond-points(0, 767){
                margin: 0;
            }

            .App.dark & {
                filter: invert(1);
            }
        }

        .App.dark & {
            background-color: $color-blue;
            color: $color-black;
        }

        &:hover {
            background-color: $color-pink !important;
        }
    }
}