.NotFoundContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;

    .notfound-text {
        font-size: 40px;
        font-weight: 600;
        color: $color-black;
        position: relative;
        text-transform: uppercase;

        .App.dark & {
            color: $color-white;
        }

        &::before {
            content: '404';
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 180px;
            color: $color-black;
            z-index: 1;
            transform: translate(-50%, -50%);
            opacity: 0.15;
            pointer-events: none;

            .App.dark & {
                color: $color-white;
            }
        }
    }

    .notfound-link {
        width: 200px;
        margin-top: 100px;
        font-size: 18px;
        padding: 10px 25px;
        text-align: center;
    }
}
