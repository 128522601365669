.ResetPasswordRequest {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .resetpassrequest-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        width: 100%;
        padding: 40px;
        background-color: #fff;
        border-radius: 40px;
        width: 100%;
        max-width: 690px;
        margin: 15px;

        @include respond-points(768, 1918){
            padding: 30px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
        }

        .resetpassrequest-form-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;

            @include respond-points(0, 767){
                margin-bottom: 16px;
            }

            @include respond-points(1280, 1920){
                margin-bottom: 24px;
            }

            &:first-of-type {
                margin-bottom: 24px;
            }

            h4 {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 20px;
                color: $color-black;
                text-align: center;
                text-transform: uppercase;

                @include respond-points(768, 1500){
                    font-size: 20px;
                }

                @include respond-points(0, 767){
                    font-size: 16px;
                    margin-bottom: 14px;
                }
            }

            .row-label {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 8px;
                color: $color-black;
                margin-right: auto;

                @include respond-points(0, 767){
                    font-family: $font-sec;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 4px;
                }
            }

            .logo {
                width: 380px;
                -webkit-user-drag: none;
                user-select: none;

                @include respond-points(768, 1918){
                    width: 304px;
                }

                @include respond-points(0, 767){
                    width: 160px;
                }
            }

            input {
                width: 100%;
            }

        }

        .btn-reset {
            color: #000 !important;
        }

        .resetpassrequest-form-login {
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            color: $color-orange;
            text-decoration: none;
            margin-top: 32px;

            @include respond-points(768, 1919){
                font-size: 24px;
                line-height: 32px;
                margin-top: 24px;
            }

            @include respond-points(0, 767){
                font-size: 16px;
                line-height: 24px;
            }

            
        }

        .resetpassrequest-form-notice {
            margin-top: 30px;
            font-size: 14px;
            color: $color-black;
            text-align: center;
            line-height: 1.4;
            font-family: $font-sec;
            font-size: 20px;
            font-weight: 600;

            span {
                color: $color-orange;
            }
        }

    }
}