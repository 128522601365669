.LessonTypePractice {
    background-color: $color-white;

    .lesson-important-notice {
        padding: 40px;
        display: flex;
        flex-direction: column;
        background: rgba(255, 135, 67, 0.2);
        border-radius: 40px;
        margin-top: 40px;

        &.disabled {
            opacity: 0.5;
            filter: blur(5px);
            pointer-events: none !important;
            user-select: none;
        }

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
        }

        .important-notice-heading {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            color: $color-orange;
            margin-bottom: 15px;

            @include respond-points(0, 1500){
                font-size: 16px;
                line-height: 24px;
            }
        }

        .important-notice-text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $color-black;
            font-family: $font-sec;

            h1, h2, h3, h4, h5 {
                margin: 10px 0 15px;
                line-height: 1.5;
            }

            p {
                margin: 0 0 10px 0;
                line-height: 1.5;
            }

            ul, ol {
                margin: 10px 0 20px 20px;

                li {
                    margin-bottom: 8px;
                    line-height: 1.5;
                }
            }

            a {
                color: $color-orange;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include respond-points(0, 1500){
                font-size: 16px;
                line-height: 24px;
            }
        }

    }
    
}