@font-face {
  font-family: 'Druk Text Wide Cy TT';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src:
    url('../../fonts/druk-heavy.woff2') format('woff2'),
    url('../../fonts/druk-heavy.woff') format('woff'),
    url('../../fonts/druk-heavy.ttf') format('truetype'),
}

@font-face {
  font-family: 'Druk Text Wide Cy TT';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('../../fonts/druk-medium.woff2') format('woff2'),
    url('../../fonts/druk-medium.woff') format('woff'),
    url('../../fonts/druk-medium.ttf') format('truetype'),
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-SemiBold'),
  url('../../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-SemiBold'),
  url('../../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Medium'),
  url('../../fonts/Montserrat-Medium.woff2') format('woff2'),
  url('../../fonts/Montserrat-Medium.woff') format('woff'),
  url('../../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}