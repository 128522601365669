.TestResponse {
    display: flex;
    flex-direction: column;

    margin: -40px -40px 0;
    
    @include respond-points(1280, 1500){
        margin: -30px -30px 0;
    }

    @include respond-points(768, 1279){
        margin: -32px -24px 0;
    }

    @include respond-points(0, 767){
        margin: -24px -15px 0;
    }


    .test-submitted-mark {
        display: flex;
        flex-direction: column;
        border-bottom: 3px solid $color-black;
        padding: 40px;

        @include respond-points(1280, 1500){
            padding: 30px;
        }

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
        }
        
        .mark-label {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            color: $color-black;

            @include respond-points(768, 1500){
                font-size: 16px;
                line-height: 24px;
            }

            @include respond-points(0, 767){
                font-size: 14px;
                line-height: 20px;
            }
        }

        .mark-value {
            font-weight: 800;
            font-size: 80px;
            line-height: 88px;
            text-transform: uppercase;
            color: $color-black;
            margin-top: 10px;

            @include respond-points(768, 1500){
                font-size: 48px;
                line-height: 56px;
            }

            @include respond-points(0, 767){
                font-size: 32px;
                line-height: 40px;
                margin-top: 5px;
            }
        }
    }

    .test-response-main {
    
        .test-response {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
    
            .respond-item {
                display: flex;
                flex-direction: column;
                border-bottom: 3px solid $color-black;
                padding: 40px;

                @include respond-points(1280, 1500){
                    padding: 30px;
                }

                @include respond-points(768, 1279){
                    padding: 32px 24px;
                }

                @include respond-points(0, 767){
                    padding: 24px 15px;
                }

                &:last-of-type{
                    border-bottom: none;
                }
                

                .question-index {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    text-transform: uppercase;
                    color: $color-orange;
                    margin-bottom: 10px;

                    @include respond-points(768, 1500){
                        font-size: 16px;
                        line-height: 24px;
                    }

                    @include respond-points(0, 767){
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .question-text {
                    font-family: $font-sec;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 48px;
                    color: $color-black;

                    @include respond-points(1280, 1500){
                        font-size: 24px;
                        line-height: 32px;
                    }

                    @include respond-points(0, 767){
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .question-answer {
                    padding: 16px;
                    margin: 8px 0 0 0;
                    display: flex;
                    border-radius: 35px;
                    border: 3px solid transparent;

                    @include respond-points(0, 1500){
                        padding: 8px;
                    }

                    .answer-marker {
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        border: 3px solid $color-black;
                        border-radius: 50%;
                        margin-right: 8px;
                        position: relative;
        
                        &:before {
                            content: '';
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            top: 3px;
                            left: 3px;
                            background-color: transparent;
        
                            @include respond-points(0, 1500){
                                width: 12px;
                                height: 12px;
                            }
                        }
        
                        @include respond-points(0, 1500){
                            min-width: 24px;
                            width: 24px;
                            height: 24px;
                        }
                    }
        
                    .answer-value {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 32px;
                        color: $color-black;
                        font-family: $font-sec;

                        @include respond-points(0, 1500){
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
        
                    &.question-answer-active {

                        .answer-marker {
                            border: 3px solid $color-orange;
                            background-color: #fff;
        
                            &:before {
                                background-color: $color-orange;
                            }
                        }
                    }

                    &.question-answer-correct {
                        background-color: rgba(76, 223, 144, 0.2);
                        border-color: #4CDF90;
                    }

                    &.question-answer-wrong {
                        background-color: rgba(253, 87, 50, 0.1);;
                        border-color: $color-orange;
                    }

                }


                .question-matching {
                    display: flex;
                    margin: 40px 0 20px;
                    overflow: auto;
                    
            
                    .question-matching-main {
                        display: flex;
                        flex-direction: column;
                    }
            
                    .matching-row {
                        display: flex;
                        border-bottom: 1px solid #ebebeb;
            
                        &:last-of-type {
                            border-bottom: none;
                        }
                        
                        .row-cell {
                            padding: 20px;
                            display: flex;
                            justify-content: center;
                            border-right: 1px solid #ebebeb;
                            min-width: 150px;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 32px;
                            color: $color-black;
                            font-family: $font-sec;
                            text-align: center;
            
                            @include respond-points(0, 1500){
                                font-size: 16px;
                                line-height: 24px;
                            }
            
                            &:last-of-type {
                                border-right: none;
                            }
            
                            &.side {
                                text-align: unset;
                            }
            
                            &.marker {
                                align-items: center;
                                justify-content: center;
            
                                .answer-marker {
                                    width: 32px;
                                    min-width: 32px;
                                    height: 32px;
                                    border: 3px solid $color-black;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                    position: relative;
            
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        top: 3px;
                                        left: 3px;
                                        background-color: transparent;
            
                                        @include respond-points(0, 1500){
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
            
                                    &.selected {
                                        border: 3px solid $color-orange;
                                        background-color: #fff;
            
                                        &:before {
                                            background-color: $color-orange;
                                        }
                                    }

                                    &.correct {
                                        box-shadow: 0 0 0 10px rgba(76, 223, 144, 0.6);
                                    }
                                    
                                    &.wrong {
                                        box-shadow: 0 0 0 10px #fbc7c7;
                                    }
            
                                    @include respond-points(0, 1500){
                                        min-width: 24px;
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
            
                    }
                }

                
            }
        }
    }
    
}
