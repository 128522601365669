.LessonScoreForm {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border-radius: 40px;
  padding: 40px;

  .error {
    margin-bottom: 30px;
    background-color: #FD5732;
    color: #fff;
    padding: 10px;
  }

  label {
    .label {
      margin-bottom: 15px;
    }
  }

  textarea {
    width: 100%;
    margin-bottom: 30px;
    resize: none;
  }

  .lesson-score-form-range {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 30px;

    @include respond-points(0, 767){
        flex-direction: column;
    }

    label {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
        @include respond-points(0, 767){
            width: 100%;
        }
      .label {
        margin-bottom: 15px;
      }
    }

    input {
      width: 100%;
      padding: 0;
      border: 0;
    }

    /*Range Reset*/
    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      cursor: pointer;
    }

    input[type="range"]:focus {
      outline: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      background-color: #FD5732;
      border-radius: 0.5rem;
      height: 0.5rem;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: -7px; /* Centers thumb on the track */
      background-color: #FD5732;
      height: 20px;
      width: 20px;
      border-radius: 100%;
    }

    input[type="range"]::-moz-range-track {
      background-color: #053a5f;
      border-radius: 0.5rem;
      height: 0.5rem;
    }

    input[type="range"]::-moz-range-thumb {
      border: none;
      border-radius: 0;
      background-color: #5cd5eb;
      height: 2rem;
      width: 1rem;
    }

    datalist, div.lesson-score-form-datalist {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 14px;

      option, span.lesson-score-form-option {
        font-size: 13px;
        display: inline-block;
        text-align: center;
        width: 20px;
      }
    }
  }
}
.shake {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}