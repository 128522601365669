.lesson-is-not-available {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 40px 0 60px;
    font-size: 24px;
    font-weight: 500px;
    color: $color-black;
    padding: 40px;
    background-color: $color-white;

    @include respond-points(768, 1279){
        padding: 32px 24px;
        font-size: 18px;
        margin: 0 0 40px;
    }

    @include respond-points(0, 767){
        padding: 32px 15px;
        font-size: 16px;
        margin: 0 0 40px;
    }

}