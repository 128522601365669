.LessonPage {

    .lesson-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
    
        @include respond-points(768, 1279){
            margin-top: 24px;
        }
    
        @include respond-points(0, 767){
            margin-top: 15px;
        }
    
        .lesson-type-label {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            color: $color-white;
            padding: 6px 8px;
            margin-right: 16px;
            background-color: $color-black;
            white-space: nowrap;
    
            &.type-1 {
                background-color: $color-green;
            }
    
            &.type-2 {
                background-color: $color-orange-light;
            }
    
            &.type-3 {
                background-color: $color-purple;
            }
    
            &.type-4 {
                background-color: $color-pink;
            }

            &.type-5, &.type-6 {
                background-color: $color-orange;
            }

            @include respond-points(1280, 1500){
                font-size: 16px;
                line-height: 24px;
                padding: 3px 8px;
            }
    
            @include respond-points(768, 1279){
                font-size: 14px;
                line-height: 20px;
                padding: 3px 8px;
            }

            @include respond-points(0, 767){
                font-size: 12px;
                line-height: 16px;
                padding: 6px 8px;
            }
        }
    
        .lesson-title-text {
            font-weight: 800;
            font-size: 64px;
            line-height: 72px;
            text-transform: uppercase;
            color: $color-black;
            margin-top: 20px;
            transition: color $att;
    
            .App.dark & {
                color: $color-white;
            }
    
            @include respond-points(1280, 1500){
                font-size: 48px;
                line-height: 56px;
                margin-top: 16px;
            }
    
            @include respond-points(768, 1279){
                font-size: 32px;
                line-height: 40px;
                margin-top: 16px;
            }
    
            @include respond-points(0, 767){
                margin-top: 8px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }
    }
    
    .lesson-content {
        margin-top: 60px;
        display: flex;

        &.lesson-content-full-width {
            flex-direction: column;

            .lesson-content-main {
                width: 100%;
            }

            .lesson-content-sidebar {
                width: 100%;
                padding-left: 0;
                padding-top: 30px;
            }
        }
    
        @include respond-points(768, 1279){
            margin-top: 48px;
        }
    
        @include respond-points(0, 767){
            margin-top: 30px;
        }
    
        @include respond-points(0, 1279){
            flex-direction: column;
        }
    
        .lesson-content-main {
            display: flex;
            flex-direction: column;
            width: 60%;
            background-color: transparent;
    
            @include respond-points(0, 1279){
                width: 100%;
            }
    
            .lesson-deadline-notice {
                padding: 40px;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                text-transform: uppercase;
                color: $color-black;
                background-color: $color-orange;
                margin-bottom: 40px;

                @include respond-points(768, 1500){
                    padding: 24px;
                    font-size: 16px;
                    line-height: 24px;
                }

                @include respond-points(0, 767){
                    padding: 24px 15px;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 32px;
                }
            }
    
            .lesson-main {
                padding: 60px 40px;
    
                @include respond-points(1280, 1500){
                    padding: 30px;
                }
    
                @include respond-points(768, 1279){
                    padding: 32px 24px;
                }
    
                @include respond-points(0, 767){
                    padding: 24px 15px;
                }
                
                .lesson-text {
                    font-family: $font-sec;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    color: $color-black;
                    word-break: break-word;
    
                    h1, h2, h3, h4, h5 {
                        margin: 10px 0 15px;
                        line-height: 1.5;
                    }
        
                    p {
                        margin: 0 0 10px 0;
                        line-height: 1.5;
                    }
        
                    ul, ol {
                        margin: 20px 0 20px 30px;
        
                        li {
                            margin-bottom: 7px;
                            line-height: 1.5;
                        }
                    }
        
                    a {
                        color: $color-orange;
                        text-decoration: none;
    
                        &:hover {
                            text-decoration: underline;
                            color: $color-purple;
                        }
                    }

                    &.disabled {
                        opacity: 0.5;
                        filter: blur(5px);
                        pointer-events: none !important;
                        user-select: none;
                    }
    
                    @include respond-points(0, 1500){
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    
        .lesson-content-sidebar {
            display: flex;
            flex-direction: column;
            width: 40%;
            padding-left: 30px;
    
            @include respond-points(0, 1279){
                margin-top: 25px;
                width: 100%;
                padding-left: 0;
            }
        }
    }


    .LessonTypeLecture {
        .lesson-main {
            .lesson-text {
                ul {
                    list-style: none;
                    margin: 24px 0 !important;
            
                    li {
                        display: flex;
                        margin-bottom: 16px !important;

                        &:last-of-type {
                            margin-bottom: 0 !important;
                        }
            
                        &::before {
                            content: '';
                            width: 32px;
                            min-width: 32px;
                            height: 32px;
                            background-image: url('../../images/mark-orange.svg');
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            margin-right: 24px;

                            @include respond-points(0, 1500){
                                width: 24px;
                                min-width: 24px;
                                height: 24px;
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }
        }
    }


}



