.LessonVideo {
    display: flex;
    position: relative;
    width: 100%;

    .react-video-wrap {
        display: flex;
    
        iframe {
            height: 450px;
    
            @include respond-points(401, 600){
                height: 300px;
            }
    
            @include respond-points(0, 400){
                height: 230px;
            }
        }
    }

    .vimeo-video-wrap {
        position: relative;
        padding-bottom: 56%;
        overflow: hidden;
        max-width: 100%;
        background: black;
        width: 100%;
        
        iframe {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}