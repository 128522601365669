.LessonItem {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include respond-points(0, 767){
        margin-bottom: 32px;
        flex-direction: column;
    }

    .lesson-type {
        display: flex;
        flex-direction: column;

        .lesson-type-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color: $color-white;
            padding: 6px 8px;
            margin-right: 16px;
            background-color: $color-black;
            white-space: nowrap;
    
            &.type-1 {
                background-color: $color-green;
            }
    
            &.type-2 {
                background-color: $color-orange-light;
            }
    
            &.type-3 {
                background-color: $color-purple;
            }
    
            &.type-4 {
                background-color: $color-pink;
            }

            &.type-5, &.type-6 {
                background-color: $color-orange;
            }

            &.blocked {
                background-color: $color-black;
                opacity: 0.25;
            }

            @include respond-points(0, 767){
                width: fit-content;
                margin-bottom: 8px;
            }

            @include respond-points(0, 1500){
                padding: 3px 8px;
            }
        }
    }


    .lesson-name {
        position: relative;
        a {
            font-family: $font-sec;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $color-black;
            text-decoration: none;
            display: inline-block;
            margin-right: 30px;

            @include respond-points(0, 1500){
                font-size: 16px;
                line-height: 24px;
            }

            @include respond-points(0, 767){
                margin-right: unset;
            }

            &::after {
                content: '';
                min-width: 16px;
                width: 16px;
                height: 16px;
                margin-left: 10px;
                background-image: url('../../../../images/arrow.svg');
                background-size: 16px 16px;
                background-position: center;
                background-repeat: no-repeat;
                display: inline-block;
                position: relative;
                left: 20px;
                opacity: 0;
                filter: invert(1);
                transform: scaleX(-1);
                transition: left $att, opacity $att;

                @include respond-points(0, 767){
                    display: none;
                }
            }

            &:hover {
                text-decoration: underline;

                &::after {
                    left: 0;
                    opacity: 1;
                }
            }
        }
    }
    .lesson-status {
        height: 20px;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;

        @include respond-points(0, 767){
            right: unset;
        }
    }

    .lesson-livestream-starts {
        width: 100%;
        margin-top: 7px;
        font-family: $font-sec;
        font-size: 17px;
        line-height: 30px;

        &.accent {
            font-weight: 600;
            color: $color-orange;
            padding-left: 30px;
            background-image: url('../../../../images/icon-exclamation.svg');
            background-position: top 3px left;
            background-size: 24px 24px;
            background-repeat: no-repeat;

            @include respond-points(0, 1500){
                background-size: 20px 20px;
                background-position: top 2px left;
                padding-left: 26px;
                
            }
        }

        @include respond-points(0, 1500){
            font-size: 14px;
            line-height: 24px;
        }
    }

    .lesson-disabled {
        display: flex;
        flex-direction: column;
        cursor: not-allowed;
        opacity: 0.4;

        .lesson-title {
            font-family: $font-sec;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $color-black;
            text-decoration: none;

            @include respond-points(0, 1500){
                font-size: 16px;
                line-height: 24px;
            }
        }

        .lesson-notice {
            font-family: $font-sec;
            font-size: 16px;
            line-height: 24px;
            margin-top: 3px;

            @include respond-points(0, 1500){
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}