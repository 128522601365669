.TestStartView {
    display: flex;
    flex-wrap: wrap;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    @include respond-points(0, 1279){
        flex-direction: column;
    }

    .test-description {
        padding: 40px;
        display: flex;
        flex-direction: column;
        background: rgba(253, 87, 50, 0.2);
        border-radius: 40px;
        width: calc(50% - 30px);
        margin-right: 30px;

        &.disabled {
            opacity: 0.5;
            filter: blur(5px);
            pointer-events: none !important;
            user-select: none;
        }

        @include respond-points(0, 1279){
            width: 100%;
            margin: 0 0 40px 0;
        }

        @include respond-points(1280, 1500){
            padding: 40px 30px;
        }

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
            margin: 0 0 32px 0;
        }

        .test-description-heading {
            font-weight: 800;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            color: $color-orange;
            margin-bottom: 15px;

            @include respond-points(768, 1500){
                font-size: 24px;
                line-height: 32px;
            }

            @include respond-points(0, 767){
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }

        .test-description-text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $color-black;
            font-family: $font-sec;

            h1, h2, h3, h4, h5 {
                margin: 10px 0 15px;
                line-height: 1.5;
            }

            p {
                margin: 0 0 24px 0;
                line-height: 1.5;

                @include respond-points(0, 767){
                    margin: 0 0 15px 0;
                }
            }

            ul, ol {
                margin: 10px 0 20px 20px;

                li {
                    margin-bottom: 8px;
                    line-height: 1.5;

                    &::marker {
                        color: $color-orange;
                    }
                }
            }

            a {
                color: $color-orange;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include respond-points(0, 1500){
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .test-info {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 30px;

        &.disabled {
            opacity: 0.5;
            filter: blur(5px);
            pointer-events: none !important;
            user-select: none;
        }

        @include respond-points(768, 1279){
            flex-direction: row;
        }

        @include respond-points(0, 1279){
            width: 100%;
            padding-left: 0;
        }

        .test-info-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 60px;

            @include respond-points(768, 1279){
                width: 50%;
                margin-bottom: 0;
            }

            @include respond-points(0, 767){
                margin-bottom: 24px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            .info-item-label {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                text-transform: uppercase;
                color: $color-black;

                @include respond-points(768, 1500){
                    font-size: 16px;
                    line-height: 24px;
                }

                @include respond-points(0, 767){
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .info-item-value {
                font-weight: 800;
                font-size: 80px;
                line-height: 88px;
                text-transform: uppercase;
                color: $color-black;
                margin-top: 10px;

                @include respond-points(768, 1500){
                    font-size: 48px;
                    line-height: 56px;
                }

                @include respond-points(0, 767){
                    font-size: 32px;
                    line-height: 40px;
                    margin-top: 5px;
                }
            }
        }
    }


    .start-test-btn {
        width: 100%;
        margin: 40px 0 0 0;
    }
}