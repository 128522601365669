.LessonTypeLecture {
    background-color: $color-white;
    
    .lesson-text-heading {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: $color-black;
        margin-bottom: 32px;

        @include respond-points(768, 1500){
            font-size: 24px;
            line-height: 32px;
        }

        @include respond-points(0, 767){
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

