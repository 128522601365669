$color-black: #000;
$color-white: #fff;
$color-orange: #FD5732;
$color-orange-light: #FF8743;
$color-pink: #F85BAE;
$color-purple: #D693FF;
$color-blue: #ABE1FF;
$color-green: #43715B;
$color-gray: #D0D0D0;

$att: .15s linear;

$font: 'Druk Text Wide Cy TT', sans-serif;
$font-sec: 'Montserrat', sans-serif;




