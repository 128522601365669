.CourseCalendar {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include respond-points(768, 1279) {
        margin-bottom: 24px;
    }

    @include respond-points(0, 767) {
        margin-bottom: 15px;
    }

    .course-calendar-heading {
        display: flex;
        flex-direction: column;
        padding: 40px;
        background-color: $color-pink;
        border-radius: 40px 40px 0 0;

        @include respond-points(1280, 1500) {
            padding: 30px;
        }

        @include respond-points(768, 1279) {
            padding: 24px;
        }

        @include respond-points(0, 767) {
            padding: 24px 15px;
        }

        .calendar-title {
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            text-transform: uppercase;
            color: $color-black;

            @include respond-points(0, 1500) {
                font-size: 24px;
                line-height: 32px;
            }

        }
    }

    .course-calendar-main {
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        padding: 50px 40px;
        border-radius: 0 0 40px 40px;

        @include respond-points(1280, 1500) {
            padding: 40px 30px;
        }

        @include respond-points(768, 1279) {
            padding: 32px 24px;
        }

        @include respond-points(0, 767) {
            padding: 24px 15px;
        }



        .react-calendar {
            display: flex;
            flex-direction: column;

            .react-calendar__navigation {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 40px;

                .react-calendar__navigation__label {
                    pointer-events: none;

                    &:first-letter {
                        text-transform: capitalize
                    }
                }

                button {
                    background-color: transparent;
                    color: $color-black;
                    font-size: 20px;
                    font-weight: 400;
                    font-family: $font;
                    border: none;
                    padding: 0 3px;

                    @include respond-points(0, 767) {
                        font-size: 16px;
                    }
                }

                .react-calendar__navigation__arrow {

                    &.react-calendar__navigation__prev2-button,
                    &.react-calendar__navigation__next2-button {
                        display: none;
                    }

                    padding: 15px;
                    background-color: $color-blue;
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                    position: relative;
                    transition: background-color $att;

                    &:hover {
                        background-color: $color-purple;
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-image: url('../../../../images/arrow.svg');
                        background-position: center;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        filter: invert(1);
                        transform: translateX(0);
                        transition: transform $att;
                    }

                    &.react-calendar__navigation__next-button {
                        transform: scaleX(-1);

                        &:hover {
                            &::before {
                                transform: translateX(-2px);
                            }
                        }
                    }

                    &.react-calendar__navigation__prev-button {
                        &:hover {
                            &::before {
                                transform: translateX(-2px);
                            }
                        }
                    }

                }
            }


            .react-calendar__month-view__weekdays {
                margin-bottom: 10px;

                .react-calendar__month-view__weekdays__weekday {
                    display: flex;
                    justify-content: center;

                    abbr {
                        text-decoration: none;
                        text-transform: uppercase;
                        font-size: 16px;

                        @include respond-points(0, 767) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .react-calendar__month-view__days {

                .react-calendar__month-view__days__day {
                    padding: 15px 5px;
                    background-color: #1111110c;
                    border: none;
                    border-radius: 0;
                    color: $color-black;
                    font-size: 17px;
                    font-weight: 400px;
                    font-family: $font-sec;
                    border: 2px solid $color-white;
                    transition: background-color .1s;
                    cursor: pointer;

                    @include respond-points(0, 767) {
                        font-size: 14px;
                    }

                    &:hover {
                        background-color: #11111120;
                    }

                    &.has-lesson {
                        background-color: $color-orange;
                        color: $color-white;
                    }

                    &.react-calendar__tile--active {
                        background-color: $color-green;
                        color: $color-white;
                    }

                }
            }
        }


        .calendar-lessons-main {
            display: flex;
            flex-direction: column;
            margin-top: 35px;

            @include respond-points(0, 767) {
                margin-top: 25px;
            }

            .calendar-lessons-list {
                display: flex;
                flex-direction: column;

                .lesson-item {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                    padding: 15px;
                    background-color: #1111110c;

                    @include respond-points(0, 767) {
                        margin-bottom: 8px;
                        padding: 8px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .lesson-time {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: $font;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 15px;
                        text-transform: uppercase;
                        color: $color-white;
                        padding: 6px 8px;
                        margin-right: 16px;
                        background-color: $color-orange;
                        white-space: nowrap;
                        margin-right: 8px;
                        width: 90px;

                        @include respond-points(0, 767) {
                            font-size: 13px;
                            line-height: 13px;
                            width: 80px;
                        }
                    }

                    .lesson-name {
                        font-family: $font-sec;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 28px;
                        color: $color-black;
                        text-decoration: none;
                        display: inline-block;

                        @include respond-points(768, 1280) {
                            font-size: 20px;
                            line-height: 24px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        &::after {
                            content: '';
                            min-width: 16px;
                            width: 16px;
                            height: 16px;
                            margin-left: 10px;
                            background-image: url('../../../../images/arrow.svg');
                            background-size: 16px 16px;
                            background-position: center;
                            background-repeat: no-repeat;
                            display: inline-block;
                            position: relative;
                            left: 20px;
                            opacity: 0;
                            filter: invert(1);
                            transform: scaleX(-1);
                            transition: left $att, opacity $att;

                            @include respond-points(0, 767) {
                                display: none;
                            }
                        }

                        &:hover {
                            text-decoration: underline;

                            &::after {
                                left: 0;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .calendar-lessons-link {
        margin-top: 30px;
        text-align: center;
        a {
            display: flex;
            align-items: center;
            padding: 8px 24px;
            background-color: #abe2ff;
            border-radius: 40px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            justify-content: center;
            color: #000;
            cursor: pointer;
            text-decoration: none;
            transition: background-color 0.15s linear;
            &:hover {
                background-color: #F85BAE;
            }
        }
    }
}