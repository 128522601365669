.BlockGeneralInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include respond-points(768, 1280){
        margin-bottom: 24px;
    }

    @include respond-points(0, 767){
        margin-bottom: 15px;
    }

    .block-content {
        display: flex;
        flex-direction: column;

        .block-head {
            padding: 40px;
            background-color: $color-orange;
            border-radius: 40px 40px 0px 0px;
            color: $color-black;
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            text-transform: uppercase;
            word-break: break-word;

            @include respond-points(1280, 1500){
                padding: 40px 30px;
            }
    
            @include respond-points(768, 1279){
                padding: 32px 24px;
            }
    
            @include respond-points(0, 767){
                padding: 24px 15px;
            }

            @include respond-points(768, 1500){
                font-size: 24px;
                line-height: 32px;
            }
    
            @include respond-points(0, 767){
                font-size: 16px;
                line-height: 24px;
            }

        }

        .general-info-main {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px;
            background-color: $color-white;
            border-radius: 0 0 40px 40px;
            position: relative;

            @include respond-points(1280, 1500){
                padding: 40px 30px;
            }

            @include respond-points(768, 1279){
                padding: 32px 24px;
            }

            @include respond-points(0, 767){
                padding: 24px 15px;
            }



            .block-fields {
                display: flex;
                flex-direction: column;
                width: 100%;

                .field-item {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 40px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .item-label {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 24px;
                        color: $color-black;
                        margin-bottom: 8px;
                    }

                    .item-input {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        &:disabled {
                            border-color: #00000030;
                        }
                        &:read-only {
                            border-color: #00000030;
                        }
                    }
                }
            }


            .block-action {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 40px;

                .btn-general-info {
                    width: 100%;
                }
            }
        }
    }
}