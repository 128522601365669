.LessonTypeWebinarChat {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $color-white;
    border-radius: 40px;
    margin-bottom: 30px;
    overflow: hidden;

    .chat-title {
        padding: 40px;
        background-color: $color-orange;
        border-radius: 40px 40px 0px 0px;
        color: $color-white;
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        word-break: break-word;
        transition: color $att;

        .App.dark & {
            color: $color-black;
        }

        @include respond-points(1280, 1500){
            padding: 40px 30px;
        }

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
        }

        @include respond-points(768, 1500){
            font-size: 24px;
            line-height: 32px;
            padding: 40px 30px;
        }

        @include respond-points(0, 767){
            font-size: 16px;
            line-height: 24px;
        }
    }

    .chat-main {
        display: flex;
        flex-direction: column;
        height: 500px;
        position: relative;

        .preloader {
            position: absolute;
            left: 0;
            top: 20px;
            z-index: 100;
        }
        
        iframe {
            opacity: 1;
            transition: opacity 0.2s;
            pointer-events: unset;

            &.hidden {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}