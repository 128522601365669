.Header {
    height: 90px;

    @include respond-points(0, 767){
        height: 64px;
    }
    
    .container {
        display: flex;
        align-items: center;
        height: 100%;

        .logo {
            height: 40px;

            @include respond-points(768, 1919){
                height: 30px;
            }

            @include respond-points(0, 767){
                height: 32px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                -webkit-user-drag: none;
                user-select: none;
            }
        }

        .header-actions {
            display: flex;
            align-items: center;
            margin-left: auto;

            .account-btn {
                padding: 15px 24px;
                font-size: 16px;
                line-height: 24px;
                margin-left: 40px;
                display: flex;
                justify-content: center;
                text-decoration: none;
                
                @include respond-points(768, 1279){
                    margin-left: 24px;
                }

                @include respond-points(768, 1919){
                    font-size: 14px;
                    line-height: 20px;
                }

                @include respond-points(0, 767){
                    padding: 8px 16px;
                    font-size: 12px;
                    line-height: 16px;
                    margin-left: 8px;
                }
            }

            .theme-switch {
                height: 40px;
                display: flex;
                border-radius: 25px;
                background-color: #fff;
                position: relative;
                align-items: center;
                overflow: hidden;
                min-width: 102px;
                cursor: pointer;

                @include respond-points(0, 767){
                    min-width: 32px;
                    height: 32px;
                    width: 32px;
                }

                &:hover {
                    @include respond(768){
                        .switch-inner .switcher-circle {
                            background-color: $color-purple !important;
                        }
                    }
                }

                .switch-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(200% - 40px);
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 8px;
                    transition: left $att;

                    @include respond-points(0, 767){
                        position: relative;
                        padding: 0;
                        width: 100%;
                    }
                    
                    .label {
                        width: calc(100% - 44px);
                        text-transform: uppercase;
                        color: $color-black;
                        font-size: 12px;
                        line-height: 16px;
                        display: flex;
                        max-width: 49px;
                        min-width: 49px;

                        @include respond-points(0, 767){
                            display: none;
                        }
                    }


                    .switcher-circle {
                        min-width: 40px;
                        height: 40px;
                        border-radius: 20px;
                        background-color: $color-blue;
                        margin: 0 5px;
                        background-image: url('../../../images/moon.svg');
                        background-size: 24px 24px;
                        background-position: center;
                        background-repeat: no-repeat;
                        transform: rotate(0);
                        transform-origin: center center;
                        transition: .15s linear, transform .25s linear;

                        @include respond-points(0, 767){
                            min-width: 32px;
                            height: 32px;
                            border-radius: 16px;
                            margin: 0;
                            background-size: 16px 16px;
                        }
                    }
                }

                &.active {
                    .switch-inner {

                        @include respond(768){
                            left: calc(-100% + 40px);
                        }

                        .switcher-circle {
                            background-color: $color-black;
                            background-image: url('../../../images/sun.svg');
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
        }
    }
}