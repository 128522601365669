.CoursePage {

    .course-subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        transition: color $att;
        color: $color-black;
        margin-top: 60px;
        word-break: break-word;

        .App.dark & {
            color: $color-white;
        }

        @include respond-points(768, 1279) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 24px;
        }

        @include respond-points(0, 767) {
            font-size: 14px;
            line-height: 20px;
            margin-top: 15px;
        }
    }

    .course-title {
        font-weight: 800;
        font-size: 80px;
        line-height: 88px;
        text-align: center;
        text-transform: uppercase;
        color: $color-black;
        transition: color $att;
        margin-top: 30px;
        word-break: break-word;

        .App.dark & {
            color: $color-orange;
        }

        @include respond-points(1280, 1919) {
            font-size: 64px;
            line-height: 72px;
        }

        @include respond-points(768, 1279) {
            font-size: 48px;
            line-height: 56px;
            margin-top: 16px;
        }

        @include respond-points(0, 767) {
            font-size: 24px;
            line-height: 32px;
            margin-top: 15px;
        }
    }

    .course-stats {
        display: flex;
        justify-content: center;
        margin-top: 60px;

        @include respond-points(0, 1279) {
            margin: 40px 0 0 0;
            flex-wrap: wrap;
        }

        .stats-block {
            padding: 0 20px;
            flex: 1;
            max-width: 450px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond-points(500, 1279) {
                margin-top: 24px;
                width: 50%;
                max-width: none;
                flex: none;
            }

            @include respond-points(0, 499) {
                margin-top: 24px;
                width: 100%;
                max-width: none;
                flex: none;

                &:first-of-type {
                    margin-top: 0;
                }
            }


            .stats-value {
                font-weight: 800;
                font-size: 132px;
                line-height: 134px;
                color: $color-black;
                transition: color $att, -webkit-text-stroke-color $att;

                @include respond-points(0, 1400) {
                    font-size: 80px;
                    line-height: 88px;
                }

            }

            .stats-label {
                margin-top: 16px;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                text-transform: uppercase;
                transition: color $att;
                color: $color-black;

                .App.dark & {
                    color: $color-white;
                }

                @include respond-points(0, 1400) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }


            &:nth-of-type(odd) {

                .stats-value {
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 3px;
                    -webkit-text-stroke-color: $color-black;

                    .App.dark & {
                        -webkit-text-stroke-color: $color-white;
                    }
                }
            }

            &:nth-of-type(even) {
                .stats-value {
                    color: $color-black;

                    .App.dark & {
                        color: $color-white;
                    }
                }
            }
        }
    }


    .course-description {
        display: flex;
        flex-direction: column;
        margin: 60px auto 0;
        padding: 40px;
        border-radius: 40px;
        background-color: $color-white;

        @include respond-points(768, 1279) {
            padding: 32px 24px;
        }

        @include respond-points(0, 767) {
            padding: 32px 15px;
        }

        .course-description-heading {
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: $color-black;
            text-transform: uppercase;
            margin-bottom: 24px;

            @include respond-points(768, 1500) {
                margin-bottom: 18px;
            }

            @include respond-points(0, 767) {
                margin-bottom: 10px;
            }

            @include respond-points(0, 1500) {
                font-size: 24px;
                line-height: 32px;
            }

        }

        .course-description-text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $color-black;
            font-family: $font-sec;

            @include respond-points(0, 767) {
                font-size: 16px;
                line-height: 24px;
            }

        }
    }

    .course-content {
        display: flex;
        flex-direction: column;

        .course-content-main {
            display: flex;
            margin-top: 30px;

            @include respond-points(768, 1279) {
                margin-top: 24px;
            }

            @include respond-points(0, 767) {
                margin-top: 15px;
            }

            @include respond-points(0, 1279) {
                flex-direction: column;
            }

            .course-sidebar {
                display: flex;
                flex-direction: column;
                width: 40%;
                padding-left: 30px;

                @include respond-points(0, 1279) {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 20px;
                }
            }


            .course-no-access {
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    font-size: 20px;
                    line-height: 1.5;
                    font-weight: 600;
                    text-align: center;
                    color: $color-black;
                    margin-bottom: 15px;
                    font-family: $font-sec;

                    .App.dark & {
                        color: $color-white;
                    }
                }
            }
        }

    }
}