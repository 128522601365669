.TestQuestion {
    display: flex;
    flex-direction: column;

    .question-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
        color: $color-orange;
        margin-bottom: 10px;

        @include respond-points(768, 1500){
            font-size: 16px;
            line-height: 24px;
        }

        @include respond-points(0, 767){
            font-size: 12px;
            line-height: 16px;
        }
    }

    .question-text {
        font-family: $font-sec;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: $color-black;

        @include respond-points(1280, 1500){
            font-size: 24px;
            line-height: 32px;
        }

        @include respond-points(0, 767){
            font-size: 16px;
            line-height: 24px;
        }
    }

    .question-image {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        margin-bottom: 15px;

        img {
            width: 100%;
        }
    }

    .question-options {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        @include respond-points(1280, 1500){
            margin-top: 40px;
        }

        @include respond-points(0, 767){
            margin-top: 32px;
        }

        .answer-item {
            padding: 5px 0;
            margin: 10px 0;
            display: flex;
            cursor: pointer;

            &:first-of-type {
                margin-top: 0;
            }

            .answer-marker {
                width: 32px;
                min-width: 32px;
                height: 32px;
                border: 3px solid $color-black;
                border-radius: 50%;
                margin-right: 8px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    top: 3px;
                    left: 3px;
                    background-color: transparent;

                    @include respond-points(0, 1500){
                        width: 12px;
                        height: 12px;
                    }
                }

                @include respond-points(0, 1500){
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                }
            }

            .answer-value {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: $color-black;
                font-family: $font-sec;

                @include respond-points(0, 1500){
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            &.answer-item-active {

                .answer-marker {
                    border: 3px solid $color-orange;
                    background-color: #fff;

                    &:before {
                        background-color: $color-orange;
                    }
                }
            }

        }
    }

    .question-matching {
        display: flex;
        margin: 40px 0 20px;
        overflow: auto;
        

        .question-matching-main {
            display: flex;
            flex-direction: column;
        }

        .matching-row {
            display: flex;
            border-bottom: 1px solid #ebebeb;

            &:last-of-type {
                border-bottom: none;
            }
            
            .row-cell {
                padding: 20px;
                display: flex;
                justify-content: center;
                border-right: 1px solid #ebebeb;
                min-width: 150px;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: $color-black;
                font-family: $font-sec;
                text-align: center;

                @include respond-points(0, 1500){
                    font-size: 16px;
                    line-height: 24px;
                }

                @include respond-points(0, 767){
                    padding: 10px;
                }

                &:last-of-type {
                    border-right: none;
                }

                &.side {
                    text-align: unset;
                }

                &.marker {
                    align-items: center;
                    justify-content: center;

                    .answer-marker {
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        border: 3px solid $color-black;
                        border-radius: 50%;
                        margin-right: 8px;
                        position: relative;
                        cursor: pointer;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            top: 3px;
                            left: 3px;
                            background-color: transparent;

                            @include respond-points(0, 1500){
                                width: 12px;
                                height: 12px;
                            }
                        }

                        &.selected {
                            border: 3px solid $color-orange;
                            background-color: #fff;

                            &:before {
                                background-color: $color-orange;
                            }
                        }

                        @include respond-points(0, 1500){
                            min-width: 24px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }

        }
    }
}