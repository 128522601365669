.LessonTypePracticeAnswer {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .lesson-answer {
        display: flex;
        flex-direction: column;

        .lesson-answer-heading {
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            text-transform: uppercase;
            color: $color-white;
            padding: 40px;
            background-color: $color-black;
            transition: background-color $att, color $att;
            border-radius: 40px 40px 0 0;

            .App.dark & {
                background-color: $color-blue;
                color: $color-black;
            }

            @include respond-points(1280, 1500) {
                padding: 40px 30px;
            }

            @include respond-points(768, 1279) {
                padding: 32px 24px;
            }

            @include respond-points(0, 767) {
                padding: 24px 15px;
            }

            @include respond-points(768, 1500) {
                font-size: 24px;
                line-height: 32px;
            }

            @include respond-points(0, 767) {
                font-size: 16px;
                line-height: 24px;
            }

        }

        .lesson-answer-main {
            display: flex;
            flex-direction: column;
            background-color: $color-white;
            border-radius: 0 0 40px 40px;
            padding: 40px;

            @include respond-points(1280, 1500) {
                padding: 40px 30px;
            }

            @include respond-points(768, 1279) {
                padding: 32px 24px;
            }

            @include respond-points(0, 767) {
                padding: 24px 15px;
            }

            .lesson-form {
                display: flex;
                flex-direction: column;

                textarea {
                    width: 100%;
                    height: 280px;
                    resize: none;
                    margin-bottom: 40px;
                }
            }

            .lesson-answer-submitted {
                display: flex;
                flex-direction: column;

                .answer-submitted-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .submitted-text-label {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        padding: 6px 8px;
                        background-color: $color-green;
                        margin-bottom: 24px;

                        @include respond-points(768, 1500) {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 16px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 14px;
                            line-height: 20px;
                            margin-bottom: 10px;
                        }
                    }

                    .submitted-text-value {
                        display: flex;
                        flex-direction: column;

                        p {
                            font-family: $font-sec;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 32px;
                            color: $color-black;
                            word-break: break-word;
                            margin-bottom: 7px;

                            &:empty:before {
                                content: ' ';
                                white-space: pre;
                            }

                            @include respond-points(0, 1500) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        a {
                            color: $color-orange;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .answer-submitted-mark {
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;

                    @include respond-points(0, 767) {
                        margin-top: 32px;
                    }

                    .submitted-mark-label {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        text-transform: uppercase;
                        color: #000000;
                        margin-bottom: 10px;

                        @include respond-points(768, 1500) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 14px;
                            line-height: 20px;
                            margin: 0;
                        }
                    }

                    .submitted-mark-value {
                        font-weight: 800;
                        font-size: 80px;
                        line-height: 88px;
                        text-transform: uppercase;
                        color: #000000;
                        display: flex;
                        align-items: flex-end;


                        @include respond-points(768, 1500) {
                            font-size: 48px;
                            line-height: 56px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 32px;
                            line-height: 40px;
                        }
                    }
                }

                .answer-submitted-comment {
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;

                    @include respond-points(0, 767) {
                        margin-top: 32px;
                    }

                    .submitted-comment-label {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        text-transform: uppercase;
                        color: #000000;
                        margin-bottom: 10px;

                        @include respond-points(768, 1500) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 14px;
                            line-height: 20px;
                            margin: 0;
                        }
                    }

                    .submitted-comment-value {
                        display: flex;
                        flex-direction: column;

                        p {
                            font-family: $font-sec;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 32px;
                            color: $color-black;
                            word-break: break-word;
                            margin-bottom: 7px;

                            &:empty:before {
                                content: ' ';
                                white-space: pre;
                            }

                            @include respond-points(0, 1500) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        a {
                            color: $color-orange;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}