* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::selection {
    color: $color-black;
    background: $color-pink;
}

body {
    font-family: $font;
    padding: 0;
    margin: 0;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    background-position: center top;
    background-size: 100% auto;

    background-color: $color-blue;
    background-image: url('../../images/confertti-light.svg');

    transition: background-color $att;
    
    @include respond-points(768, 1280){
        background-size: 200% auto;
    }

    @include respond-points(0, 767){
        background-size: 500% auto;
    }

    &.dark {
        background-color: $color-black;
        background-image: url('../../images/confertti-black.svg');
    }
}

.page-wrap {
    margin-bottom: 50px;
    margin-top: 20px;

    @include respond-points(768, 1279){
        margin-top: 15px;
    }

    @include respond-points(0, 767){
        margin-top: 5px;
    }
}

.container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 30px;

    @include respond-points(768, 1280){
        padding: 0 24px;
    }

    @include respond-points(0, 767){
        padding: 0 15px;
    }
}


input, textarea {
    padding: 25px;
    color: $color-black;
    border: 3px solid $color-black;
    outline: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    font-family: $font;

    @include respond-points(0, 767){
        padding: 15px;
        font-size: 16px;
    }

    &.row-input-error {
        border: 3px solid $color-orange;
    }

    &:active, &:focus {
        border-color: $color-pink;
        caret-color: $color-pink;
    }

    &:disabled {
        opacity: 0.8;
        pointer-events: none;
    }
}

a {
    &.btn, &.btn-sec {
        text-decoration: none;
    }
}

.preloader {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
    -webkit-user-drag: none;
    user-select: none;
    
    &.preloader-fw {
        width: 100%;
    }

    .App.dark & {
        filter: invert(1);
    }

    &.black {
        filter: none !important;
    }

    &.white {
        filter: invert(1) !important;
    }
}

.btn {
    width: 100%;
    padding: 24px 48px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: $color-white;
    background: $color-orange;
    border-radius: 999px;
    border: none;
    font-family: $font;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: color $att, background-color $att;
    user-select: none;

    &:hover, &:focus {
        background-color: $color-pink;
    }

    @include respond-points(768, 1919){
        font-size: 24px;
        line-height: 32px;
    }

    @include respond-points(0, 767){
        font-size: 16px;
        line-height: 24px;
        padding: 16px 24px;
    }

    .App.dark & {
        color: $color-black;
    }

    img {
        height: 40px;

        @include respond-points(768, 1919){
            height: 32px;
        }
        
        @include respond-points(0, 767){
            height: 24px;
        }
    }

    &.btn-sec {
        background-color: $color-blue;
        color: $color-black;

        &:hover, &:focus {
            background-color: $color-pink;
        }
    }

    &:disabled, &.disabled {
        background-color: $color-gray;
        pointer-events: none;
    }

}


.page-title {
    font-weight: 800;
    font-size: 64px;
    line-height: 72px;
    text-transform: uppercase;
    color: $color-black;
    margin-top: 16px;
    transition: color $att;

    &.page-title-center {
        text-align: center;
    }

    &.large {
        font-size: 80px;
        line-height: 88px;

        @include respond-points(1280, 1500){
            font-size: 64px;
            line-height: 72px;
        }
    
        @include respond-points(768, 1279){
            font-size: 48px;
            line-height: 56px;
        }

        @include respond-points(0, 767){
            font-size: 24px;
            line-height: 32px;
            font-weight: 800;
        }
    }

    .App.dark & {
        color: $color-white;
    }

    @include respond-points(1280, 1500){
        font-size: 48px;
        line-height: 56px;
    }

    @include respond-points(768, 1279){
        font-size: 32px;
        line-height: 40px;
    }

    @include respond-points(0, 767){
        margin-top: 8px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
    }
}

.page-subtitle {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 20px;
    color: $color-black;
}
