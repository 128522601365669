.Certificate {

    .certificate-guest-head {
        display: flex;
        justify-content: center;
        margin: 30px 0 50px;

        img {
            height: 50px;
        }
    }


    .сertificate-main {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        background-color: #fff;
        padding: 40px;
        border-radius: 40px;

        @include respond-points(768, 1279){
            margin-top: 48px;
        }

        @include respond-points(0, 767){
            margin-top: 30px;
            padding: 24px 15px;
        }

        
        .certificate-info {
            display: flex;
            flex-direction: column;    
    
            .certificate-user-label {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                text-transform: uppercase;
                color: $color-black;
                margin-bottom: 10px;

                @include respond-points(768, 1500){
                    font-size: 16px;
                    line-height: 24px;
                }

                @include respond-points(0, 767){
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 5px;
                }
            }
    
            .certificate-user {
                font-weight: 800;
                font-size: 48px;
                line-height: 56px;
                text-transform: uppercase;
                color: $color-black;

                @include respond-points(768, 1500){
                    font-size: 32px;
                    line-height: 40px;
                }

                @include respond-points(0, 767){
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
    
    
            .certificate-fields {
                display: flex;
                flex-wrap: wrap;
                margin-top: 48px;
    
                .field-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 48px;

                    @include respond-points(0, 1279){
                        margin-bottom: 32px;
                    }

                    &.field-half {
                        width: 50%;

                        @include respond-points(0, 1279){
                            width: 100%;
                        }
                        
                    }

                    .field-label {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        text-transform: uppercase;
                        color: $color-black;

                        @include respond-points(768, 1500){
                            font-size: 16px;
                            line-height: 24px;
                        }
        
                        @include respond-points(0, 767){
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: 500;
                            margin-bottom: 5px;
                        }
                    }

                    .field-value {
                        font-weight: 800;
                        font-size: 48px;
                        line-height: 56px;
                        text-transform: uppercase;
                        color: $color-black;

                        @include respond-points(768, 1500){
                            font-size: 32px;
                            line-height: 40px;
                        }
        
                        @include respond-points(0, 767){
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 500;
                        }

                        &.value-points {
                            color: $color-orange;
                        }

                        
                    }
                }
                
            }
    
        }

        .certificate-actions {
            display: flex;
            flex-direction: column;

            .download-certificate {
                display: flex;

                span {
                    display: flex;
                    align-items: center;

                    &::before {
                        content: '';
                        width: 52px;
                        height: 40px;
                        margin-right: 16px;
                        background-image: url('../../images/icon-download.svg');
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;

                        @include respond-points(768, 1919){
                            height: 32px;
                            width: 42px;
                        }
                        
                        @include respond-points(0, 767){
                            height: 24px;
                            width: 31px;
                            margin-right: 8px;
                        }
    
                    }
                }
            }
        }
    
    }
}

