.LessonMaterials {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;

    .materials-title {
        padding: 40px;
        background-color: $color-black;
        border-radius: 40px 40px 0px 0px;
        color: $color-white;
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        word-break: break-word;
        transition: color $att, background-color $att;

        .App.dark & {
            background-color: $color-blue;
            color: $color-black;
        }

        @include respond-points(1280, 1500){
            padding: 40px 30px;
        }

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
        }

        @include respond-points(768, 1500){
            font-size: 24px;
            line-height: 32px;
            padding: 40px 30px;
        }

        @include respond-points(0, 767){
            font-size: 16px;
            line-height: 24px;
        }

    }

    .materials-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        background-color: $color-white;
        border-radius: 0 0 40px 40px;
        position: relative;

        @include respond-points(1280, 1500){
            padding: 40px 30px;
        }

        @include respond-points(768, 1279){
            padding: 32px 24px;
        }

        @include respond-points(0, 767){
            padding: 24px 15px;
        }

        .material-item {
            margin-bottom: 25px;
            width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }

            .material-item-link {
                display: flex;
                color: $color-black;
                text-decoration: none;
                width: 100%;
                
                .item-icon {
                    margin-right: 18px;
                    width: 64px;
                    height: 64px;
                    object-fit: contain;
                    object-position: center;

                    .App.dark & {
                        filter: brightness(0) saturate(100%) invert(38%) sepia(67%) saturate(2177%) hue-rotate(342deg) brightness(113%) contrast(107%);
                    }
            
                    @include respond-points(768, 1500){
                        width: 48px;
                        height: 48px;
                        margin-right: 10px;
                    }
            
                    @include respond-points(0, 767){
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                    }
                }

                .item-title {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    text-transform: uppercase;
                    color: $color-black;
                    margin-top: 15px;
                    width: 100%;

                    @include respond-points(768, 1500){
                        font-size: 16px;
                        line-height: 24px;
                    }
            
                    @include respond-points(0, 767){
                        font-size: 14px;
                        line-height: 24px;
                        margin-top: 8px;
                    }
                }

                &:hover {
                    .item-icon {
                        filter: brightness(0) saturate(100%) invert(60%) sepia(80%) saturate(1093%) hue-rotate(216deg) brightness(103%) contrast(105%) !important;
                    }

                    .item-title {
                        color: $color-purple;
                    }
                }
            }
        }

        
    }
}