.LessonPage {

    .lesson-navigation {
        display: flex;
        position: sticky;
        top: calc(100vh - (72px + 24px));

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            background-color: $color-black;
            width: calc(50% - 3px);
            text-decoration: none;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            color: $color-white;
            transition: color $att, background-color $att;

            @include respond-points(0, 1600){
                font-size: 16px;
                line-height: 24px;
            }

            @include respond-points(0, 767){
                padding: 16px;
            }

            .App.dark & {
                background-color: $color-blue;
                color: $color-black;

                &::before, &::after {
                    filter: invert(1);
                }

                &.disabled {
                    background-color: #818181;
                }
            }

            .nav-label {
                @include respond-points(0, 767){
                    display: none;
                }
            }

            &.disabled {
                background-color: #818181;
            }

            &.prev {
                &::before {
                    content: '';
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    display: flex;
                    background-image: url('../../../../images/arrow.svg');
                    background-position: center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    margin-right: 16px;
                    left: 0;
                    transition: filter $att, left $att, right $att;
                    position: relative;

                    @include respond-points(0, 1600){
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                    }

                    @include respond-points(0, 767){
                        margin: 0;
                    }
                }
            }

            &.next {
                margin-left: auto;
                
                &::after {
                    content: '';
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    display: flex;
                    background-image: url('../../../../images/arrow.svg');
                    background-position: center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    margin-left: 16px;
                    transform: scaleX(-1);
                    right: 0;
                    transition: filter $att, left $att, right $att;
                    position: relative;

                    @include respond-points(0, 1600){
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                    }

                    @include respond-points(0, 767){
                        margin: 0;
                    }
                }
            }

            &:first-of-type {
                margin-right: 6px;
            }

            &:hover {
                background-color: $color-purple !important;

                @include respond(768){
                    &::after {
                        right: -5px;
                    }
    
                    &::before {
                        left: -5px;
                    }
                }
            }
        }

    }
}