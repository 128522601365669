.course-main-block {
    display: flex;
    flex-direction: column;
    width: 60%;

    @include respond-points(0, 1279) {
        width: 100%;
    }

    .ModulesList {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 3px solid $color-black;

        .modules-list-title {
            padding: 56px 40px;
            background-color: $color-black;
            border-bottom: 3px solid $color-black;
            color: $color-white;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            text-transform: uppercase;
            transition: color $att, background-color $att;

            .App.dark & {
                background-color: $color-blue;
                color: $color-black;
            }

            @include respond-points(1280, 1500) {
                font-size: 32px;
                line-height: 40px;
                padding: 46px 40px;
            }

            @include respond-points(768, 1279) {
                font-size: 32px;
                line-height: 40px;
                padding: 32px 24px;
            }

            @include respond-points(0, 767) {
                font-size: 24px;
                line-height: 32px;
                padding: 24px 15px;
            }
        }

        .course-modules-list {
            display: flex;
            flex-direction: column;
        }

        .course-closed {
            padding: 40px;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            color: $color-black;
            background-color: $color-orange;

            @include respond-points(768, 1500) {
                padding: 24px;
                font-size: 16px;
                line-height: 24px;
            }

            @include respond-points(0, 767) {
                padding: 24px 15px;
                font-size: 14px;
                line-height: 20px;
            }
        }

    }

    .qualifying-course-pass {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        p {
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
            text-align: center;
            color: $color-black;
            margin-bottom: 20px;
            transition: color $att;

            @include respond-points(0, 767) {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
            }

            .App.dark & {
                color: $color-white;
            }
        }
    }


}