.BlockCertificates {
    display: flex;
    flex-direction: column;

    .block-content {
        display: flex;
        flex-direction: column;

        .block-head {
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            text-transform: uppercase;
            color: $color-white;
            padding: 40px;
            background-color: $color-black;
            transition: background-color $att, color $att;

            .App.dark & {
                background-color: $color-blue;
                color: $color-black;
            }

            @include respond-points(1280, 1500) {
                padding: 40px 30px;
            }

            @include respond-points(768, 1279) {
                padding: 32px 24px;
            }

            @include respond-points(0, 767) {
                padding: 24px 15px;
            }

            @include respond-points(768, 1500) {
                font-size: 24px;
                line-height: 32px;
            }

            @include respond-points(0, 767) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .certificates-content-main {
            display: flex;
            flex-direction: column;
            background-color: $color-white;
            padding: 40px;

            @include respond-points(1280, 1500) {
                padding: 40px 30px;
            }

            @include respond-points(768, 1279) {
                padding: 32px 24px;
            }

            @include respond-points(0, 767) {
                padding: 24px 15px;
            }

            .no-data {
                font-family: $font-sec;
                font-size: 20px;
                line-height: 24px;
                font-weight: 600;
                color: $color-black;
            }

            .certificates-disclaimer {
                display: flex;
                flex-direction: column;
                background-color: rgba(253, 87, 50, 0.1);
                padding: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $color-orange;
                font-family: $font-sec;
            }

            .certificates-list {
                display: flex;
                flex-direction: column;
                margin-top: 48px;

                @include respond-points(0, 767) {
                    margin-top: 40px;
                }

                .certificate-item {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 48px;

                    @include respond-points(0, 767) {
                        margin-bottom: 40px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .certificate-title {
                        width: 100%;
                        font-weight: 500;
                        font-size: 32px;
                        line-height: 40px;
                        text-transform: uppercase;
                        color: $color-black;
                        margin-bottom: 16px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }

                        @include respond-points(768, 1500) {
                            font-size: 24px;
                            line-height: 32px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 14px;
                            line-height: 20px;
                            margin-bottom: 8px;
                        }
                    }

                    .certificate-points {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        text-transform: uppercase;
                        color: $color-orange;

                        @include respond-points(768, 1500) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        @include respond-points(0, 767) {
                            font-size: 14px;
                            line-height: 20px;
                            width: 100%;
                            margin-bottom: 8px;
                        }
                    }

                    .certificate-actions {
                        display: flex;

                        @include respond(768) {
                            margin-left: auto;
                        }

                        .certificate-btn {
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 32px;
                            text-transform: uppercase;
                            text-decoration: none;
                            position: relative;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            @include respond-points(768, 1500) {
                                font-size: 16px;
                                line-height: 24px;
                            }

                            @include respond-points(0, 767) {
                                font-size: 14px;
                                line-height: 20px;
                                width: 100%;
                                margin-bottom: 8;
                            }

                            &:hover {
                                &:not(.btn-disabled) {
                                    text-decoration: underline;
                                }
                            }

                            &.btn-open {
                                color: $color-green;
                            }

                            &.btn-generate {
                                color: $color-black;

                                img {
                                    height: 32px;
                                }
                            }

                            &.btn-disabled {
                                cursor: default;

                                .btn-disabled-text,
                                .certificate-unavailable-action {
                                    opacity: 0.6;
                                }
                            }

                            .certificate-unavailable-action {
                                width: 24px;
                                height: 24px;
                                margin-left: 8px;
                                background-image: url('../../../../images/question.svg');
                                background-size: 100% 100%;
                                background-position: center;
                                background-repeat: no-repeat;
                                cursor: pointer;

                                @include respond-points(0, 767) {
                                    width: 20px;
                                    height: 20px;
                                }

                                &:hover {
                                    &+.certificate-unavailable-notice {
                                        opacity: 1;
                                        pointer-events: unset;
                                    }
                                }
                            }

                            .certificate-unavailable-notice {
                                opacity: 0;
                                pointer-events: none;
                                position: absolute;
                                bottom: 63px;
                                right: -115px;
                                width: 285px;
                                padding: 10px 16px;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                color: $color-white;
                                font-family: $font-sec;
                                background-color: $color-black;
                                text-transform: none;
                                transition: opacity .2s;
                                z-index: 1;

                                @include respond-points(768, 1000) {
                                    right: 12px;
                                }

                                @include respond-points(0, 767) {
                                    width: calc(100vw - 30px);
                                    max-width: 260px;
                                    right: -95px;
                                    bottom: 55px;
                                    font-size: 12px;
                                    line-height: 16px;
                                }

                                .App.dark & {
                                    background-color: $color-blue;
                                    color: $color-black;
                                }

                                &::before {
                                    content: '';
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 0 32px 32px 0;
                                    border-color: transparent $color-black transparent transparent;
                                    left: calc(50% - 16px);
                                    bottom: -32px;

                                    @include respond-points(768, 1000) {
                                        left: calc(100% - 32px);
                                    }

                                    @include respond-points(0, 767) {
                                        left: calc(50% - 8px);
                                    }

                                    .App.dark & {
                                        border-color: transparent $color-blue transparent transparent;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}