.LessonTypeTest {
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    .lesson-test {
        display: flex;
        flex-direction: column;
        position: relative;
        
        @include respond-points(0, 767){
            padding-top: 40px;
        }

        .test-controls {
            margin-top: 30px;
            display: flex;
            justify-content: center;
    
    
            .test-controls-main{
                width: 100%;
                display: flex;

                @include respond-points(0, 767){
                    flex-direction: column-reverse;
                }

                .test-control {
                    width: calc(50% - 20px);
                    padding: 24px;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 40px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #000000;
                    border: 3px solid $color-blue;
                    background-color: $color-blue;
                    border-radius: 100px;
                    cursor: pointer;
                    transition: background-color $att, border-color $att, color $att, opacity $att;
                    user-select: none;


                    @include respond-points(768, 1500){
                        font-size: 24px;
                        line-height: 32px;
                    }

                    @include respond-points(0, 767){
                        font-size: 16px;
                        line-height: 24px;
                        padding: 16px;
                    }

                    @include respond-points(0, 767){
                        width: 100%;
                        margin-bottom: 15px;

                        &:nth-last-of-type() {
                            margin-bottom: 0;
                        }
                    }

                    &:hover {
                        background-color: $color-purple;
                        border-color: $color-purple;
                    }

                    &.test-control-disabled {
                        user-select: none;
                        pointer-events: none;
                        opacity: 0.2;
                        background-color: #fff;
                        color: $color-black;
                        border-color: $color-black;
                    }

                    &.test-control-prev {
                        background-color: #fff;
                        color: $color-black;
                        border-color: $color-black;

                        &:hover {
                            background-color: $color-orange-light;
                        }
                    }

                    &.test-control-next {
                        margin-left: auto;
                    }
                }
    
    
                .test-control-submit {
                    margin-left: auto;
                    color: $color-black;
                    background-color: $color-orange;
                    border-color: $color-orange;

                    &:hover {
                        background-color: $color-pink;
                        border-color: $color-pink;
                    }
                }

            }
        }

        .test-counter{
            display: flex;
            align-items: center;
            color: $color-black;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
            position: absolute;
            right: 0;
            top: 0;
            font-variant-numeric: tabular-nums;

            @include respond-points(768, 1500){
                font-size: 16px;
                line-height: 24px;
            }
    
            @include respond-points(0, 767){
                font-size: 14px;
                line-height: 20px;
            }


            &::before {
                content: '';
                min-width: 32px;
                width: 32px;
                height: 32px;
                background-image: url('../../../../../images/clock.svg');
                background-size: 100% 100%;
                background-position: center;
                margin-right: 10px;

                @include respond-points(768, 1500){
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                }
        
                @include respond-points(0, 767){
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }

            }

            &.counter-warn {
                color: $color-orange;
            }
        }
    }

    
}