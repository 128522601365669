.CourseRating {
    display: flex;
    flex-direction: column;

    .course-rating-heading {
        display: flex;
        flex-direction: column;
        padding: 40px;
        background-color: $color-orange;
        border-radius: 40px 40px 0 0;

        @include respond-points(1280, 1500) {
            padding: 30px;
        }

        @include respond-points(768, 1279) {
            padding: 24px;
        }

        @include respond-points(0, 767) {
            padding: 24px 15px;
        }

        .rating-title {
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            text-transform: uppercase;
            color: $color-black;

            @include respond-points(0, 1500) {
                font-size: 24px;
                line-height: 32px;
            }

        }

        .rating-last-updated {
            font-family: $font-sec;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $color-black;
            margin-top: 8px;

            @include respond-points(0, 767) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }


    .rating-list-block {
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        padding: 60px 40px;
        border-radius: 0 0 40px 40px;

        @include respond-points(1280, 1500) {
            padding: 40px 30px;
        }

        @include respond-points(768, 1279) {
            padding: 32px 24px;
        }

        @include respond-points(0, 767) {
            padding: 24px 15px;
        }


        .rating-list {
            display: flex;
            flex-direction: column;

            .rating-item {
                display: flex;
                margin-bottom: 32px;

                @include respond-points(0, 1500) {
                    margin-bottom: 24px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                .item-user {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    text-transform: uppercase;
                    color: $color-black;

                    @include respond-points(0, 1500) {
                        font-size: 16px;
                        line-height: 24px;
                    }

                }

                .item-score {
                    margin-left: auto;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    text-align: right;
                    text-transform: uppercase;
                    color: $color-orange;

                    @include respond-points(0, 1500) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

        }


        .rating-show-all {
            margin-top: 40px;
            background-color: transparent;
            border: 3px solid $color-black;
            color: $color-black;
            transition: background-color $att;

            &:hover {
                @include respond(768) {
                    background-color: $color-purple;
                }
            }
        }
    }

}